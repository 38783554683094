import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import FrontEndCarousel from "../components/FrontEndCarousel";
import DigitalArtCarousel from "../components/DigitalArtCarousel";

// Images
import bat from "../assets/images/bat.gif";
import skeleton from "../assets/images/skeleton.gif";
import animationsheet from "../assets/images/animationsheet.gif";
import pirateguy from "../assets/images/pirateguy.gif";

export default function Portfolio() {
  function createDots() {
    let components = [];
    for (let i = 1; i < 7; i++) {
      components.push(<div className="dot" key={i}></div>);
    }
    return components;
  }
  return (
    <Container>
      <div className="title-wrapper" style={{ marginTop: "100px", marginBottom: "100px", position: "relative" }}>
        <h4>Portfolio</h4>
        <h1>My Works - Frontend</h1>
        <div className="dots-wrapper frontend">
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
        </div>
      </div>
      <FrontEndCarousel />

      <div className="title-wrapper" style={{ marginTop: "200px", marginBottom: "100px", position: "relative" }}>
        <h4>Portfolio</h4>
        <h1>My Works - Digital Art</h1>
        <div className="dots-wrapper frontend">
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
        </div>
      </div>
      <DigitalArtCarousel />

      <div className="title-wrapper" style={{ marginTop: "100px", marginBottom: "100px", position: "relative" }}>
        <h4>Portfolio</h4>
        <h1>My Works - Pixel Art</h1>
        <div className="dots-wrapper pixel-art">
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
        </div>
      </div>
      <Row className="pixel-art-row">
        <Col sm="3" md="3" style={{ textAlign: "center" }} className="pixel-art-col">
          <img src={bat} alt="pixel bat" />
        </Col>
        <Col sm="3" md="3" style={{ textAlign: "center" }} className="pixel-art-col">
          <img src={animationsheet} alt="pixel pirate" />
        </Col>
        <Col sm="3" md="3" style={{ textAlign: "center" }} className="pixel-art-col">
          <img src={skeleton} alt="pixel skeleton" />
        </Col>
        <Col sm="3" md="3" style={{ textAlign: "center" }} className="pixel-art-col">
          <img src={pirateguy} alt="pixel pirate guy" />
        </Col>
      </Row>
    </Container>
  );
}
