import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Pages
import Contact from "../Contact";
import About from "../About";
import Portfolio from "../Portfolio";

// Images
import profilepic from "../../assets/images/picturehero.png";

function Home() {
  const { t } = useTranslation();
  // const currentTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "light";

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function createDots() {
    let components = [];
    for (let i = 1; i < 7; i++) {
      components.push(<div className="dot" key={i}></div>);
    }
    return components;
  }

  return (
    <div className="home">
      <Container fluid="lg">
        <Row>
          <Col sm={12} lg={6} className="text-home-wrapper">
            <div className="big-text-wrap">
              <h1 className="home-text big">Hey, Isabella here!</h1>
              <h1 className="home-text big">
                I'm a<span className="home-text big colored"> digital </span>
                product designer and a developer.
              </h1>
            </div>
            <div className="mt-4" style={{ width: "100px" }}>
              <span className="scroll-btn">
                <span className="mouse">
                  <span></span>
                </span>

                <p>Explore!</p>
              </span>
            </div>
          </Col>
          <Col sm={12} lg={6} className="hero-outer-wrap">
            <div className="hero-wrapper">
              <div className="dots-wrapper">
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
              </div>

              <div className="bubble-purple">
                <img src={profilepic} className="w-100" alt="hero pic" />
              </div>
            </div>
          </Col>
          <div className="bubble-pink"></div>
        </Row>
      </Container>

      <About />

      <Portfolio />

      <Container id="contact">
        <Contact />
      </Container>
      <div className="scroll-top-wrapper" style={{ display: showScroll ? "flex" : "none" }} onClick={scrollTop}>
        <FontAwesomeIcon icon={["fas", "chevron-up"]} className="scrollTop" />
      </div>
    </div>
  );
}

export default Home;
