// import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
// import logo from "../assets/images/logo.png";
// const logo = require("../assets/images/logo.png");
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import mypic from "../assets/images/hamburg6.jpg";
function About() {
  // const { t } = useTranslation();

  function createDots() {
    let components = [];
    for (let i = 1; i < 7; i++) {
      components.push(<div className="dot" key={i}></div>);
    }
    return components;
  }

  return (
    <Container className="about" style={{ marginTop: "100px", position: "relative" }}>
      <div className="title-wrapper">
        <h4>Skills</h4>
        <h1>My Expertise</h1>
        <div className="dots-wrapper skills">
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
          <Row>{createDots()}</Row>
        </div>
      </div>
      <Row id="about" className="content-about-wrap">
        <Col sm={12} md={6} style={{ zIndex: "5" }}>
          <div className="skill-wrapper frontend">
            <div className="box-skill-container">
              <div className="skill-title">
                <div className="icon-wrapper frontend">
                  <FontAwesomeIcon icon={["fas", "laptop-code"]} />
                </div>
                <h4>Frontend Development</h4>
              </div>
              <p className="text-about">
                React.Js, Vue.js, HTML5, JavaScript, TypeScript, Vuetify, Wordpress, Bootstrap, CSS3/SASS, jQuery. Just some of the tools I mostly work with when developing
                frontend.
              </p>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} style={{ zIndex: "5" }}>
          <div className="skill-wrapper">
            <div className="box-skill-container">
              <div className="skill-title">
                <div className="icon-wrapper graphics">
                  <FontAwesomeIcon icon={["fas", "cubes"]} />
                </div>
                <h4>2D / 3D Graphics</h4>
              </div>
              <p className="text-about">
                I use Photoshop to create 2D assets, from pixel art to animations. Blender 3D is my software of choice to create 3D models and animations.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="about-two">
        <Col sm={12} md={6} style={{ zIndex: "5" }}>
          <div className="skill-wrapper science">
            <div className="box-skill-container">
              <div className="skill-title">
                <div className="icon-wrapper datascience">
                  <FontAwesomeIcon icon={["fas", "chart-pie"]} />
                </div>
                <h4>Data Science</h4>
              </div>
              <p className="text-about">
                Machine learning and statistics are powerful tools to find information in data that can be easily overlooked by human eyes. Python and R are my languages of choice
                here.
              </p>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} style={{ zIndex: "5" }}>
          <div className="skill-wrapper game">
            <div className="box-skill-container">
              <div className="skill-title">
                <div className="icon-wrapper game">
                  <FontAwesomeIcon icon={["fas", "gamepad"]} />
                </div>
                <h4>Game Development</h4>
              </div>
              <p className="text-about">
                I started my journey in game development in 2020 with Unity 3D. Unity can also be used to create awesome applications, 3D and 2D animations, and interactive models.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "100px", justifyContent: "center" }}>
        <iframe
          width="760"
          height="430"
          src="https://www.youtube.com/embed/AIuJkfNzcFY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Row>
      <div className="title-wrapper mb-4" style={{ marginTop: "100px" }}>
        <h4>Nice to meet you!</h4>
        <h1>Hey, I'm Isabella, not your usual developer.</h1>
      </div>
      <Row style={{ alignItems: "center", position: "relative" }}>
        <FontAwesomeIcon icon={["fab", "react"]} className="react-icon" />
        <FontAwesomeIcon icon={["fab", "vuejs"]} className="vuejs-icon" />
        <FontAwesomeIcon icon={["fab", "html5"]} className="html-icon" />
        <Col sm={12} lg={6}>
          <img src={mypic} className="w-100 my-pic img-fluid" alt="me" />
        </Col>
        <Col sm={12} lg={6}>
          <p>
            Originally from São Paulo, Brazil, I came to Germany for the first time in 2008 with a YEP scholarship from Rotary. I attended school for one year at the Christian
            Wirth Schule Gymnasium. In 2011 I started my studies in Biological Sciences at the University of São Paulo, in Botucatu. In 2013 I was again in Germany, with a Science
            without Borders scholarship and this time to study German in Hamburg for 7 months and two semesters of Bioprocesses Engineering at the University of Kaiserslautern.
          </p>
          <p>
            In 2016 I returned to pursue a Master's degree in Molecular Cell Biology and Neurobioloy at the University of Kaiserslautern. Soon I was granted a Deutschlandstipendium
            and I started working HiWi Jobs at the University. One of these HiWi jobs, was in the department of distance education (DISC). There I had the opportunity to work with
            content management systems, such as Typo3. I oriented my studies towards data science, performing biological data analysis using languages such as Python and F#.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px", position: "relative" }}>
        <FontAwesomeIcon icon={["fab", "python"]} className="python-icon" />
        <FontAwesomeIcon icon={["fab", "unity"]} className="unity-icon" />
        <Col sm={12} md={6}>
          <p>
            The research behind my Master's Thesis (<i>Computational prediction of protein-ligand interactions using protein's melting curves obtained through nanoDSF</i>) was
            supported by NanoTemper Technologies, a biotechnology company located in Munich. I developed a prediction algorithm in Python using statistical and machine learning
            methods, and implemented it in a cloud based serverless system hosted by AWS. The backend was designed with PostgreSQL, DynamoDB, Node.Js, Python and S3 Bucket. The
            frontend was implemented with React.Js and Amplify.
          </p>
        </Col>
        <Col sm={12} md={6}>
          <p>
            Since October, 2019, I've been working as a Frontend Developer at Ray Sono AG. This position has given me the opportunity to get involved in several projects from
            companies such as MAN, McDonald's, WMF, BMW, Amazon, etc. Since 2020 I've been dedicating myself to 3D modeling and game development. I intend to keep improving and
            learning about this interesting area as well.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
