import React, { useRef } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import emailjs from "emailjs-com";
import { Formik } from "formik";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ThankYouModal(props) {
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Thank you!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Your message has been sent!</h4>
        <p>Thank you for getting in touch with me! I will get back to you as soon as possible!</p>
      </Modal.Body>
    </Modal>
  );
}

function createDots() {
  let components = [];
  for (let i = 1; i < 7; i++) {
    components.push(<div className="dot" key={i}></div>);
  }
  return components;
}

function schemaCreator() {
  return yup.object().shape({
    name: yup.string().required("Please enter your name"),
    email: yup.string().email().required("Please enter your email"),
    phone: yup.string(),
    topic: yup.string(),
    message: yup.string().required("Please write a message"),
    contactMethod: yup.string(),
    confirm: yup.bool().oneOf([true], "Please understand the terms."),
  });
}

const ContactForm = () => {
  const [modalShow, setModalShow] = React.useState(false);

  var bubblyButtons = document.getElementsByClassName("bubbles-button");
  var animateButton = function (e) {
    e.target.classList.remove("animate");

    e.target.classList.add("animate");
    setTimeout(function () {
      e.target.classList.remove("animate");
    }, 700);
  };

  for (var i = 0; i < bubblyButtons.length; i++) {
    bubblyButtons[i].addEventListener("click", animateButton, false);
  }
  const formEl = useRef(null);

  const form = (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          topic: "",
          message: "",
          contactMethod: "",
          confirm: false,
        }}
        validationSchema={schemaCreator()}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          emailjs.send(
            "service_u1760as",
            "template_xoejkks",
            {
              email: values.email,
              name: values.name,
              phone: values.phone,
              topic: values.topic,
              contactMethod: values.contactMethod,
              message: values.message,
            },
            "user_UdVMSZmX04kE7zy0aqGNt"
          );
          setTimeout(() => {
            resetForm();
            setSubmitting(false);
            setModalShow(true);
          }, 500);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
          <div className="contact" id="contact">
            <ThankYouModal show={modalShow} onHide={() => setModalShow(false)} />
            <div className="form-outer-wrapper">
              <div className="dots-wrapper contact">
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
                <Row>{createDots()}</Row>
              </div>
              <div className="title-wrapper">
                <h4>Contact</h4>
                <h1>Let's Talk!</h1>
              </div>
              <FontAwesomeIcon icon={["far", "comments"]} className="contact-background-icon" />

              <Form className="contact-form spacer-sm" onSubmit={handleSubmit} method="POST" ref={formEl} noValidate="novalidate">
                <Form.Row className="mt-3">
                  <Form.Label htmlFor="name" className={errors.name && touched.name ? "error-input" : ""}>
                    Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="name"
                    name="name"
                    maxLength="40"
                    className={touched.name && errors.name ? "error" : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </Form.Row>
                <Form.Row className="mt-3">
                  <Form.Label className={errors.email && touched.email ? "error-input" : ""}>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    maxLength="40"
                    className={touched.email && errors.email ? "error" : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </Form.Row>
                <Form.Row className="mt-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" name="phone" maxLength="40" onChange={handleChange} onBlur={handleBlur} value={values.phone} />
                </Form.Row>
                <div className="mt-4">
                  <Form.Label className="w-100">What do you want to talk about?</Form.Label>
                  <Form.Control as="select" id="inlineFormCustomSelectPref" custom name="topic" onChange={handleChange} onBlur={handleBlur} value={values.topic}>
                    <option value="0">Choose...</option>
                    <option value="Webpage Development">Webpage Development</option>
                    <option value="Design">Design</option>
                    <option value="Game Development">Game Development</option>
                    <option value="Data Analysis">Data Analysis</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                </div>
                <div className="mt-3">
                  <Form.Label htmlFor="message">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="message"
                    rows="4"
                    maxLength="500"
                    className={touched.message && errors.message ? "error" : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />
                </div>
                <Form.Row>
                  <Form.Label className={" input-wrapper checkbox-wrapper mt-3 mb-5" + (errors.confirm ? "message-checkbox error-checkbox w-100" : "message-checkbox w-100")}>
                    <Form.Control
                      id="confirm"
                      name="confirm"
                      type="checkbox"
                      className={touched.title && errors.title ? "form-control-checkbox error" : "form-control-checkbox"}
                      value={values.confirm}
                      checked={values.confirm}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor="confirm" className={errors.confirm ? "checkbox-message error w-100" : "checkbox-message w-100 "}>
                      I understand that by submitting this form I can be contacted either by email or phone in regard of my questions.
                    </Form.Label>
                  </Form.Label>
                </Form.Row>
                <Form.Row style={{ justifyContent: "center", marginTop: "20px" }}>
                  <Form.Group className="form-group">
                    <button className="bubbles-button" type="submit">
                      Send
                    </button>
                    <button className="bubbles-button" type="reset" onClick={resetForm}>
                      Reset
                    </button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
  return form;
};

export default ContactForm;
