import React, { Suspense } from "react";
import Home from "../pages/Home/Home";
// import About from "../pages/About";
import NavBar from "../components/NavBar";
import { Route, Switch, Redirect } from "react-router-dom";

export const Routes = () => {
  document.documentElement.setAttribute("data-theme", localStorage.getItem("theme") ? localStorage.getItem("theme") : "light");

  const componentMobile = (
    <Suspense fallback="loading">
      <div className="content-outer-wrapper">
        <NavBar />
        <Switch>
          <Route exact path="/Home" component={Home} />
          <Route exact path="/">
            <Redirect to="/Home" />
          </Route>
        </Switch>
        {/* <div className="cookies-wrapper">
          

        </div> */}
      </div>
    </Suspense>
  );

  return componentMobile;
};
