import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./router/Root"; // where we are going to specify our routes

// Multi Language
import "./i18nextConf";

// Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/styles.scss";

// Slick Slider Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faInstagram, faReact, faVuejs, faHtml5, faUnity, faPython, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faLaptopCode,
  faMicroscope,
  faPaintBrush,
  faBars,
  faDownload,
  faUpload,
  faTools,
  faHeart,
  faMusic,
  faBrain,
  faGamepad,
  faSun,
  faMoon,
  faSeedling,
  faSearch,
  faVideo,
  faCubes,
  faChartPie,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faCubes,
  faChevronUp,
  faChartPie,
  faVideo,
  faComments,
  faMicroscope,
  faLaptopCode,
  faPaintBrush,
  faBars,
  faDownload,
  faUpload,
  faTools,
  faHeart,
  faMusic,
  faBrain,
  faGamepad,
  faSun,
  faMoon,
  faSeedling,
  faSearch,
  faReact,
  faVuejs,
  faHtml5,
  faUnity,
  faPython,
  faYoutube
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
