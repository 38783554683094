import React, { useState, useEffect } from "react";
import { Navbar, Nav, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.png";

// Scroll
import { HashLink } from "react-router-hash-link";

// Images
// import nightPicture from "../assets/images/night-home-page.png";
// import dayPicture from "../assets/images/day-home-page.png";

function App() {
  const { t } = useTranslation();
  const [theme, switchTheme] = useState("light");

  function themeSwitch() {
    if (theme === "light") {
      switchTheme("dark");
      document.documentElement.classList.add("theme-transition");
      document.documentElement.setAttribute("data-theme", "dark");

      window.setTimeout(function () {
        document.documentElement.classList.remove("theme-transition");
      }, 1000);
      localStorage.setItem("theme", "dark");
    } else if (theme === "dark") {
      switchTheme("light");
      document.documentElement.classList.add("theme-transition");

      document.documentElement.setAttribute("data-theme", "light");
      window.setTimeout(function () {
        document.documentElement.classList.remove("theme-transition");
      }, 1000);
      localStorage.setItem("theme", "light");
    }
  }
  return (
    <Navbar className="nav-wrapper" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="links-nav" activeKey="/home" onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}>
            <Nav.Item>
              <HashLink to="/">Home</HashLink>
            </Nav.Item>
            <Nav.Item>
              <HashLink smooth to="/#about">
                About
              </HashLink>
            </Nav.Item>
            <Nav.Item>
              <HashLink smooth to="/#portfolio">
                Portfolio
              </HashLink>
            </Nav.Item>
            <Nav.Item>
              <HashLink smooth to="/#contact">
                Contact
              </HashLink>
            </Nav.Item>
          </Nav>
          <Nav className="icons-wrapper">
            <Nav.Item>
              <a href="https://www.youtube.com/c/BellaCapilla" target="_blank" rel="noopener noreferrer" title="Youtube">
                <FontAwesomeIcon icon={["fab", "youtube"]} className="socialIcon" />
              </a>
            </Nav.Item>

            <Nav.Item>
              <a href="https://www.linkedin.com/in/isabellacapilla/" target="_blank" rel="noopener noreferrer" title="Linkedin">
                <FontAwesomeIcon icon={["fab", "linkedin"]} className="socialIcon" />
              </a>
            </Nav.Item>
            <Nav.Item>
              <a href="https://www.instagram.com/bellacapilla/" target="_blank" rel="noopener noreferrer" title="Instagram">
                <FontAwesomeIcon icon={["fab", "instagram"]} className="socialIcon" />
              </a>
            </Nav.Item>
            <Nav.Item>
              <a href="https://twitter.com/bellacapilla" target="_blank" rel="noopener noreferrer" title="Twitter">
                <FontAwesomeIcon icon={["fab", "twitter"]} className="socialIcon" />
              </a>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default App;
