import React from "react";
import { Col, Row, Carousel } from "react-bootstrap";

// Images
import Art1 from "../assets/images/Art1.jpg";
import houseforest from "../assets/images/houseforest.jpg";
import marssunset from "../assets/images/marssunset.jpg";
import rainsad from "../assets/images/rainsad.jpg";

export default function DigitalArtCarousel() {
  return (
    <div class="art-wrapper" style={{ marginTop: "100px" }}>
      {/* <div className="square"></div> */}
      <Carousel>
        <Carousel.Item>
          <div className="img-wrapper">
            <div className="img-wrapper-content">
              <img src={Art1} className="w-100" style={{ borderRadius: "20px" }} alt="Mountains" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="img-wrapper">
            <div className="img-wrapper-content">
              <img src={houseforest} className="w-100" style={{ borderRadius: "20px" }} alt="House in forest" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="img-wrapper">
            <div className="img-wrapper-content">
              <img src={marssunset} className="w-100" style={{ borderRadius: "20px" }} alt="Sunset Mars" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="portfolio" id="portfolio">
            <Col sm={12} md={6} className="content-outer-wrap">
              <div className="img-wrapper">
                <div className="img-wrapper-content">
                  <img src={rainsad} className="w-100" alt="Rain sad" />
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
