import React from "react";
import { Col, Row, Carousel } from "react-bootstrap";

// Images
import lagerfahrzeuge from "../assets/images/lagerfahzeuge.JPG";
import mcsegtrab from "../assets/images/mcsegtrab.JPG";
import sanchesepavin from "../assets/images/sanchesepavin.JPG";
import sibrol from "../assets/images/sibrol.jpg";
import timopage from "../assets/images/timopage.PNG";

export default function FrontEndCarousel() {
  return (
    <div>
      {/* Desktop Version */}
      <div className="portfolio-wrapper desktop">
        <div className="square"></div>
        <Carousel>
          <Carousel.Item>
            <Row className="portfolio" id="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={lagerfahrzeuge} className="w-100" alt="lagerfahrzeug" />
                    <div className="work-description lagerfahr">
                      <h3>Van MAN Lagerfahrzeuge</h3>
                      <p>One of the projects I took part in while working at Ray Sono AG. </p>
                      <button className="bubbles-button">
                        <a href="https://www.van.man/lagerfahrzeuge/de/de" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={mcsegtrab} className="w-100" alt="mc engenharia" />
                    <div className="work-description lagerfahr">
                      <h3>MC Engenharia</h3>
                      <p>Freelance project developed with HTML / CSS and JavaScript.</p>
                      <button className="bubbles-button">
                        <a href="http://mcsegtrab.com.br/" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={sibrol} className="w-100" alt="sibrol" />
                    <div className="work-description lagerfahr">
                      <h3>Sibrol</h3>
                      <p>Freelance project developed with Vue.js, Vue Router and Bootstrap.</p>
                      <button className="bubbles-button">
                        <a href="https://www.sibrol.com.br/" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={timopage} className="w-100" alt="timo luis" />
                    <div className="work-description lagerfahr">
                      <h3>Timo Luis Group GmbH</h3>
                      <p>Freelance project developed with Vue.js, Vue Router, Bootstrap, EmailJs and Vue-i18n.</p>
                      <button className="bubbles-button">
                        <a href="https://www.timoluis.com" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="portfolio" id="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={sanchesepavin} className="w-100" alt="sanchesepavin" />
                    <div className="work-description lagerfahr">
                      <h3>Sanches e Pavin</h3>
                      <p>Freelance project developed with Wordpress</p>
                      <button className="bubbles-button">
                        <a href="http://sanchesepavin.com.br/" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="content-outer-wrap"></Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>

      {/* Mobile */}
      <div className="portfolio-wrapper mobile">
        <div className="square"></div>
        <Carousel>
          <Carousel.Item>
            <Row className="portfolio" id="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={lagerfahrzeuge} className="w-100" alt="lagerfahrzeug" />
                    <div className="work-description lagerfahr">
                      <h3>Van MAN Lagerfahrzeuge</h3>
                      <p>One of the projects I took part in while working at Ray Sono AG. </p>
                      <button className="bubbles-button">
                        <a href="https://www.van.man/lagerfahrzeuge/de/de" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="portfolio" id="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={mcsegtrab} className="w-100" alt="mc engenharia" />
                    <div className="work-description lagerfahr">
                      <h3>MC Engenharia</h3>
                      <p>Freelance project developed with HTML / CSS and JavaScript.</p>
                      <button className="bubbles-button">
                        <a href="http://mcsegtrab.com.br/" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={timopage} className="w-100" alt="timo luis" />
                    <div className="work-description lagerfahr">
                      <h3>Timo Luis Group GmbH</h3>
                      <p>Freelance project developed with Vue.js, Vue Router, Bootstrap, EmailJs and Vue-i18n.</p>
                      <button className="bubbles-button">
                        <a href="https://www.timoluis.com" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="portfolio" id="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={lagerfahrzeuge} className="w-100" alt="lagerfahrzeug" />
                    <div className="work-description lagerfahr">
                      <h3>Van MAN Lagerfahrzeuge</h3>
                      <p>One of the projects I took part in while working at Ray Sono AG. </p>
                      <button className="bubbles-button">
                        <a href="https://www.van.man/lagerfahrzeuge/de/de" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="portfolio" id="portfolio">
              <Col sm={12} md={6} className="content-outer-wrap">
                <div className="img-wrapper">
                  <div className="img-wrapper-content">
                    <img src={sanchesepavin} className="w-100" alt="sanchesepavin" />
                    <div className="work-description lagerfahr">
                      <h3>Sanches e Pavin</h3>
                      <p>Freelance project developed with Wordpress</p>
                      <button className="bubbles-button">
                        <a href="http://sanchesepavin.com.br/" target="blank">
                          View in browser
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
